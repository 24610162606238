import React from 'react';
import styles from '../style/footer.module.css'
import headen_logo from '../images/headen_logo.png'
import phone from '../images/phone.png'

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={`wrapper ${styles.row}`}>                                             
                <div className={styles.company_logo}>
                    <img src={headen_logo}/>
                </div>
                <div className={styles.about}>                                      
                    <div>
                        <p>법인명 : 주식회사 해든앰앤씨 | 대표자 : 오현석</p>
                        <p>본사 : 서울특별시 금천구 가산디지털1로 149 신한이노플렉스 8층 805호</p>
                        <p>연구소 : 서울특별시 금천구 가산디지털1로 149 신한이노플렉스 8층 805호</p>
                        <p>사업자등록번호 : 359-88-00894 | 통신판매업 신고번호 : 제2024-서울금천-1492호</p>
                        <p>민원담당자 :  홍인기 (010-6298-2545) | E-mail : daryang@headen.co.kr</p>
                    </div>
                    <div>
                        <p>Copyright 2024. Headen M&C. All rights reserved.</p>
                        <p>사이트내 발생하는 민원/교환/환불 등에 대한 책임을 '해든앰앤씨'에서 지고 있음을 알려드립니다.</p>
                    </div>
                </div>

                <div className={styles.telephone}>                                                          
                    <div>
                        <img src={phone}/>
                        <p>고객센터</p>
                        <b>010-8301-7945</b>
                        <p>평일 10:00 ~ 17:00(주말 및 공휴일 제외)</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

